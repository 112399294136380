import logo from "./logo.svg";
import "./App.css";

function App() {
  const buttonsList = [
    {
      name: "Ames Hangar",
      url: "https://bascon.s3.us-east-2.amazonaws.com/Ames+Hangar.pdf",
    },
    {
      name: "Avborne Hangar",
      url: "https://bascon.s3.us-east-2.amazonaws.com/Avborne+Hangar.pdf",
    },
    {
      name: "Combined MRO & GA Hangars",
      url:
        "https://bascon.s3.us-east-2.amazonaws.com/Combined+MRO+%26+GA+Hangars.pdf",
    },
    {
      name: "CTAS Hangar",
      url: "https://bascon.s3.us-east-2.amazonaws.com/CTAS+Hangar.pdf",
    },
    {
      name: "Executive Jet Hangar",
      url: "https://bascon.s3.us-east-2.amazonaws.com/Executive+Jet+Hangar.pdf",
    },
    {
      name: "Lynxs Hangar",
      url: "https://bascon.s3.us-east-2.amazonaws.com/Lynxs+Hangar.pdf",
    },
    {
      name: "Qantas Hangar",
      url: "https://bascon.s3.us-east-2.amazonaws.com/Qantas+Hangar.pdf",
    },
    {
      name: "FlightServ FBO",
      url: "https://bascon.s3.us-east-2.amazonaws.com/FlightServ%202018.pdf",
    },
    {
      name: "Feam Maintenance Hanger",
      url: "https://bascon.s3.us-east-2.amazonaws.com/CVG%202%20FEAM.pdf",
    },
    {
      name: "Robson Business Card",
      url: "https://bascon.s3.us-east-2.amazonaws.com/Robson+Business+Card.pdf",
    },
    {
      name: "Tamanko Business Card",
      url:
        "https://bascon.s3.us-east-2.amazonaws.com/Tamanko+Business+Card.pdf",
    },
  ];

  const buttonsClassList = [
    "btn btn-lg btn-primary",
    "btn btn-lg btn-secondary",
    "btn btn-lg btn-success",
    "btn btn-lg btn-danger",
    "btn btn-lg btn-warning",
    "btn btn-lg btn-info",
    "btn btn-lg btn-dark",
  ];
  return (
    <div className="">
      <div className="text-center">
        <a href="https://www.basconinc.com/">
          <img width="90%" src={"basconlogo.jpg"}></img>
        </a>
      </div>

      <div className="text-center">
        <div className="row">
          {buttonsList.map((button, index) => (
            <div className="col-md-12">
              <a href={button.url} className={buttonsClassList[0]}>
                {button.name}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default App;
